.block-cta-large {

    &__wrapper {
        min-height: 700px;
        margin: 0 -12px;
        @include media-breakpoint-up(md) {
            height: 634px;
            margin: 0;
        }
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;

            @include media-breakpoint-up(md) {
                position: block;
            }
        }
        &_content {
            background-color: $orange-20;
            padding: 150px 0;
            position: relative;
            z-index: 2;
            @include media-breakpoint-up(md) {
                position: absolute;
                top: 0;
                bottom: 0;
                padding: 0;
                left:0;
                right:0;
            }
        }
        .btn__lrg {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            @include media-breakpoint-up(md) {
                margin: 0 15px;
                margin-bottom: 0;
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

}
