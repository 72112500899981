.block-quote {
  &.bg-dark-transparent {
    background-color: $darkTransparent;
  }
  &.bg-light-transparent {
    background-color: $lightTransparent;
  }
  img {
    display: inline-flex;
    height: 80px;
    width: 80px;
    border-radius: 100px;
  }
  h6 strong {
    @include media-breakpoint-down(md) {
      font-size: 1.125rem;
    }
  }
  .functie {
    font-family: $Garamond-BkNarrowIta;
  }
}
