// File: main.scss
$project-name: "SDB Website - Non-critical";
$version: "0.0.1";

/*! #{$project-name} v#{$version} */
/* The below need to be added here too, as they are used by some of the components below */
@import "abstracts/mixins";
@import "../../../../node_modules/bootstrap/scss/mixins";

// bootstrap variables
@import "vendors/bs_variables";

// non-bootstrap variables
@import "abstracts/variables";

// base
@import "base/base";
@import "base/typography";

/* Non-critical stylesheets below */
// swiper
@import "vendors/swiper-bundle.min";

// layout
@import "layout/footer";

// components / modules
@import "components/client";
@import "components/cta-large";
@import "components/cta-small";
@import "components/embed";
@import "components/harmonica";
@import "components/large-cards";
@import "components/nav-anchor";
@import "components/persoon";
@import "components/quote";
@import "components/search-form";
@import "components/small-card";
@import "components/tabs";
@import "components/text-image";
@import "components/vacancy";
@import "components/vacancy-card";
@import "components/search";
@import "components/interstitial";

// pages
