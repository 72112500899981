// Non-Bootstrap Variables

// Structure
$lead-size          : 1.75rem;
$lead-line-height   : 2.3rem;
$lead-letter-spacing: -0.8px;

$tag-size           : 0.688rem;
$tag-line-height    : 0.813rem;

$caption-size       : 1.125rem;
$caption-line-height: 1.5rem;

// Buttons
$border-radius-cta  : 6.25rem;

// Rollover effect
$rollover-transition-in: clip-path 1s ease-in-out;
$rollover-transition-out: clip-path 0.6s ease-in-out;

// Colors
$orange: var(--dynamic-color-orange);
$orange-light: var(--dynamic-color-orange-light);
$orange-20: var(--dynamic-color-orange-20);
$orange-filter: var(--dynamic-filter-orange);
$sdb-white-hieroo-dark: var(--dynamic-sdb-white-hieroo-dark);
$sdb-orange-hieroo-dark: var(--dynamic-sdb-orange-hieroo-dark);