footer { 
    background: $light;
    background: linear-gradient(180deg, rgba(250,250,250,1) 0, rgba(229,229,229,1) 160px, rgba(229,229,229,1) 0);
    z-index: 77;
    
    & .cta__secondary {
        &::before {
            background: $gray !important; //not ideal, find better fix
        } 
    }    
    a{
        color: $sdb-orange-hieroo-dark;
        text-decoration: underline;
        text-decoration-color: $orange;
    }
}