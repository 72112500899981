/* Cards */
.container__card {
    &.rollover-img-container {
        .rollover-img-container-bg {
            background-color: $orange;
        }
    }
    .rollover-img-container-bg {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-blend-mode: lighten;
        display: block;
        width: 100%;
        height: auto;
        & .rollover-img {
            transition: $rollover-transition-out;
            // clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%); /* bottom to top --------------------- */
        }
        &:hover {
            & .rollover-img {
                clip-path: polygon(0% 50%, 100% 25%, 100% 0%, 0% 0%); /* bottom to top --------------------- */
                transition: $rollover-transition-in;
            }
        }

        &.rollover-fallback {
            background-color: $orange-20!important;
            background-blend-mode: multiply;
        }
    }
    .tag {
        margin-bottom: 1rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 1.125rem;
        }
    }
    & .body {
        background: $gray;
        padding: 35px 40px 60px;
        min-height: 14.938rem;

        & p {
            min-height: 4.875rem;
            font-size: 1rem;
        }

        & > *:last-child {
            margin-bottom: 0;
        }
    }

    &[data-url] {
        cursor: pointer;
    }

    &.search__card {
        & .body {
            p {
                min-height: unset;
            }
        }
    }
}

/* Quote Card */
.container__quote {
    @include media-breakpoint-up(md) {
        min-height: 572px;
    }
    .tag {
        margin-bottom: 1rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 1.125rem;
        }
    }
    & .body {
        align-self: center;

        &.bg-orange {
            background: $orange-20;
        }

        &__img {
            display: inline-flex;
            height: 80px;
            width: 80px;
            border-radius: 100px;
            overflow: hidden;
        }

        &__detail {
            font-family: $Garamond-BkNarrowIta;
        }
        & *:last-child {
            margin-bottom: 0 !important;
        }
    }
}

.large-cards__swiper {
    .swiper-slide {
        .container__card {
            height: 100%;

            & .body {
                height: 100%;
            }
        }
    }
}