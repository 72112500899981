/* Cards */
.container__collection {
    @include media-breakpoint-down(md) {
        .grid {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-y: scroll;
        }
    }

    // issue #132: just make sure we have a way back and easy change breakpoints
    @include media-breakpoint-down(md) {
        .fw-bold {
            font-size: 18px;
            margin-bottom: 20px;
        }
        hr {
            padding: 0;
            margin: 0;
        }
    }

    &.container__collection-swiper {
        .card {
            @include media-breakpoint-down(md) {
                max-width: 220px;
            }
        }
    }

    .card {
        &[data-url] {
            cursor: pointer;
        }
        .rollover-img-container {
            background-color: $orange;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-blend-mode: lighten;
            display: block;
            width: 100%;
            height: auto;
            & .rollover-img {
                transition: $rollover-transition-out;
            }
            &:hover {
                & .rollover-img {
                    clip-path: polygon(0% 50%, 100% 25%, 100% 0%, 0% 0%); /* bottom to top --------------------- */
                    transition: $rollover-transition-in;
                }
            }
        }
    }

    .swiper-horizontal > .swiper-scrollbar {
        position: absolute;
        left: 0;
        right: unset;
        bottom: 0;
        z-index: 50;
        height: 2px;
        width: 100%;
        @include media-breakpoint-down(md) {
            left: 30px;
            right: 30px;
            width: auto;
        }
    }

    .swiper-always {
        position: relative;
        .card {
            width: 261px;
        }
    }

    .swiper-slide {
        display: flex;
        width: auto;
    }
    
}

.swiper-btn-prev {
    img {
        transform: rotate(180deg);
    }
}

.swiper-container {
    max-width: 1280px;

    &.swiper-container-constrained {
        max-width: 1120px;
    }
}