
.anchor {
    h3 {
        font-weight: 700;
        @include media-breakpoint-down(md) {
            font-family: $Garamond-BkNarrow;
            font-weight: normal;
        }
    }
        .anchor__btn-wrap {
            background: $gray;  // needed triple targeting to change bg colour

            a { margin: 0px 15px; }

            @include media-breakpoint-down(sm){     // btn__sml styling 
                .anchor__btn {
                    height: 32px;
                    margin: 20px 0px 0px 0px;
                }
            }   
            @include media-breakpoint-down(md) {
                background:none;
            }
        } 
}