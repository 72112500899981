.accordion {
  &.bg-dark-transparent {
    background-color: $darkTransparent;
  }
  &.bg-light-transparent {
    background-color: $lightTransparent;
  }

  ul, ol {
    font-family: $apercu-regular-pro;
    font-size: 1.125rem;
    line-height: 1.625rem;
    padding-left: 1rem;

    li {
      position: relative;
      padding-left: 2rem; 

      &:last-child {
        margin-bottom: 25px;
      }

      & strong,
      i,
      em {
        display: contents;
      }
    }
  }

  ul {
    li {
      &::before {
        content: "\2022";
        font-size: 20px;
        line-height: 20px;
        position: absolute; 
        left: 0; 
        top: 0.3rem; 
      }
    }
  }

  ol {
    li {
      list-style: none; 
      position: relative;
      padding-left: 2.65rem; 
      text-indent: -2rem; 

      &::before {
        content: counter(item) ".";
        counter-increment: item; 
        position: absolute;
        left: 0; 
        top: 0.3rem; 
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  &__item {
    p {
      margin-bottom: 25px;
      font-size: 1.125rem;
      line-height: 26px;
    }
  }

  &__header {
    .accordion-button {
      font-size: 35px;
      padding-top: 25px;
      background-color: transparent;
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        font-size: 28px;
        margin: 0;
      }

      &::after {
        width: 15px;
        height: 15px;
        background-size: 100% 100%;
        background-position: center;
        position: relative;
        top: 0;
        right: 15px;
      }

      &:not(.collapsed) {
        &::after {
          position: relative !important;
          top: -2px !important;
        }
      }
    }
  }
}
