#onze-mensen,
.zoeken {
  .filters {
    .btn__outline {
      border: 1px solid $dark;
      color: $dark;
      background-color: transparent;
      transition: 0.4s;
      min-width: unset;
      padding-left: 1.25rem;
      padding-right: 1.25rem;

      &:after {
        background-color: transparent;
      }

      svg {
        margin-left: 10px;
        transition: 0.4s;
        margin-top: -2px;
      }

      &:hover {
        color: white;
        background-color: $dark;

        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }

  .filter {
    background-color: $light;
    border-radius: $border-radius-cta;

    .filter-options-inner {
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-width: thin;
    }

    .filter-options-inner ul {
      padding-right: 2.5rem;
    }

    .filter-dropdown-buttons {
      background-color: $light;
      border-radius: $border-radius-cta;
      position: relative;
      z-index: 75;

      .btn {
        background-color: transparent;
        color: $dark;
        height: 52px;
        border-radius: $border-radius-cta;
        padding-left: 2.094em;
        padding-right: 2.094em;
        border: 0;
        transition: 0.4s;

        @include media-breakpoint-down(sm) {
          padding-left: 1.3em;
          padding-right: 1.3em;
        }

        svg {
          transition: 0.4s;
        }

        &[aria-expanded="false"]:hover {
          background-color: $gray;
        }

        &[aria-expanded="true"] {
          background-color: $dark;
          color: white;

          svg {
            transform: rotate(180deg);

            path {
              fill: white;
            }
          }
        }
      }
    }

    .filter-dropdown-menu {
      position: absolute;
      left: 0;
      background-color: $gray;
      transform: translate3d(0px, 0, 0px) !important;
      padding: 94px 48px 48px 48px;
      border-radius: 1.65rem 1.65rem;
      border: 0;
      z-index: 74;
      min-width: 244px;

      &[data-popper-placement="top-start"] {
        padding: 48px 48px 94px 48px;
      }

      .form-check {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        .form-check-input[type="radio"] {
          border-radius: 0;

          &:not(:checked) {
            background-color: $light;
            border-color: $light;
          }
        }

        input[type="checkbox"] {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 1.25rem;
          height: 1.25rem;
          border: none;
          border-radius: 0;
          margin-right: 0.5rem;
          transition: 0.4s;

          &:not(:checked) {
            background-color: $light;
            border-color: $light;
          }

          &:checked {
            background-color: $orange;
            border-color: $orange;
          }
        }

        label {
          color: $dark;
          cursor: pointer;
        }

        .form-check-input:checked[type="checkbox"] {
          --bs-form-check-bg-image: none;
        }

        .form-check-input:focus {
          box-shadow: none;
        }
      }
    }
  }

  .pagination {
    background-color: $lightTransparent;
    border-radius: 100px;
    height: 32px;

    ul {
      list-style: none;

      li {
        display: inline-block;

        &:not(.pagination-icon) {
          position: relative;
          top: -2px;
        }

        .btn__sml {
          min-width: unset;
          color: $dark;

          &.btn__icon {
            svg {
              path {
                transition: 0.4s;
              }
            }

            &:hover {
              svg {
                path {
                  fill: white;
                }
              }
            }
          }

          &:hover {
            color: white;
          }

          &:after {
            background-color: transparent;
          }

          &.active {
            color: white !important;

            &:after {
              background-color: $dark;
            }
          }
        }
      }
    }
  }
}

#onze-mensen {
  .filter-dropdown-menu {
    min-width: 280px !important;
  }
}
