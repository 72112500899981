.client {
  .client__copy {
    max-width: 545px;
    h3 {
      font-weight: 700;
    }
    p {
      font-size: 1.125rem;
    }
  }
  .client__swiper {
    pointer-events: none;
    background-color: white;
    padding: 2.25rem 0;
    @include media-breakpoint-up(md) {
      border-radius: 200px;
    }
    .swiper-wrapper {
      transition-timing-function: linear !important;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      .swiper-slide {
        &.client__swiper__item {
          width: 150px;
          height: 100px;
          position: relative;
          img {
            filter: grayscale(1);
            position: absolute;
            height: 100%;
            width: 100%;
            inset: 0px;
            object-fit: contain;
            color: transparent;
          }
        }
      }
    }
  }
}
