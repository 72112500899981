.persoon {
    @include media-breakpoint-down(md) {
        height: auto;
        padding-top: 6.25rem;
        padding-bottom: 6.25rem;
    }

    &__info-item {
        min-width: fit-content;
        @include media-breakpoint-down(md) {
            padding-bottom: 25px;
        }
    }

    &::before {
        position: absolute;
        background: #f6f6f6;
        border-radius: $border-radius-cta;
        content: "";
        min-height: 120px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;

        @include media-breakpoint-down(md) {
            width: 174px;
            height: 400px;
            padding: 20px;
        }
    }
}
