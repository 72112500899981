.block-text-image {

  ul, ol {
    padding-left: 1rem;
    
    li {
      position: relative; 
      padding-left: 1.5rem; 
      font-family: $apercu-regular-pro;
      font-size: 1.125rem;
      line-height: 1.625rem;
      
      &::before {
        content: "\2022"; 
        position: absolute;
        left: 0; 
        top: 0.3rem; 
        font-size: 20px;
        line-height: 20px;
        color: inherit; 
      }
    }
  }

  &.bg-light-transparent {
    background-color: $lightTransparent;
  }
  &.bg-dark-transparent {
    background-color: $darkTransparent;
  }
  .text-image__container {
    max-width: 1115px;
    @include media-breakpoint-down(lg) {
      padding: 0;
    }

    .text-image__container__row {
      flex-direction: column;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
      .text-image__intro {
        order: 2;
        @include media-breakpoint-down(lg) {
          padding: 20px;
        }
        @include media-breakpoint-up(lg) {
          order: 1;
        }
        .text-image__intro__heading {
          font-weight: 700;
        }
        .text-image__intro__copy {
          font-size: 1.125rem;
          p {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .text-image__image {
        order: 1;
      }
    }
  }
}
