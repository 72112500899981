// Font-Face
@mixin font-face($font-family, $file-path) {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path}.ttf") format('truetype'),
    url("#{$file-path}.woff") format('woff'),
    url("#{$file-path}.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}

@mixin font-face-otf($font-family, $file-path) {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path}.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}
  
  // Font-Weight
  $apercu-families: (
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 800,
  );

// If the family exists in the map.
@mixin apercu($apercu-weight) {
  @if map-has-key($apercu-families, $apercu-weight) {
    // Get the family value.
    $apercu-weight-value: map-get($apercu-families, $apercu-weight);

    // Write css:
    font-family: $body-font-family;
    font-weight: $apercu-weight-value;
    font-style: normal;

    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid apercu-family: #{$apercu-weight}.';
  }
}


// Padding
@mixin inner-padding($left, $right) {
    padding-right: $right;
    padding-left: $left;
  }

@mixin object-padding($top, $bottom) {
    padding-right: $top;
    padding-left: $bottom;
  }

@mixin header-padding($bottom) {
    padding-bottom: $bottom;
}

@mixin container-padding($top, $right, $bottom, $left) {
    padding-top: $top;
    padding-right: $right;
    padding-bottom: $bottom;
    padding-left: $left;
}