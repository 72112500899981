/* Font-Family - Garamond */
@include font-face-otf("Garamond-BdNarrow", "../../assets/font/ITCGaramondStd-BdNarrow");
@include font-face-otf("Garamond-BdNarrowlta", "../../assets/font/ITCGaramondStd-BdNarrowIta");
@include font-face-otf("Garamond-BkNarrow", "../../assets/font/ITCGaramondStd-BkNarrow");
@include font-face-otf("Garamond-BkNarrowIta", "../../assets/font/ITCGaramondStd-BkNarrowIta");
@include font-face-otf("Garamond-LtNarrow", "../../assets/font/ITCGaramondStd-LtNarrow");
@include font-face-otf("Garamond-LtNarrowIta", "../../assets/font/ITCGaramondStd-LtNarrowIta");

/* Font-Family - apercu */
@include font-face("apercu-bold", "../../assets/font/apercu-bold-pro");
@include font-face("apercu-bold-italic", "../../assets/font/apercu-bold-italic-pro");
@include font-face("apercu-italic", "../../assets/font/apercu-italic-pro");
@include font-face("apercu-light-italic", "../../assets/font/apercu-light-italic-pro");
@include font-face("apercu-medium-italic", "../../assets/font/apercu-medium-italic-pro");
@include font-face("apercu-medium-pro", "../../assets/font/apercu-medium-pro");
@include font-face("apercu-mono-medium-pro", "../../assets/font/apercu-mono-medium-pro");
@include font-face("apercu-regular-pro", "../../assets/font/apercu-regular-pro");

$apercu-light: "apercu-light";
$apercu-light-italic: "apercu-light-italic";
$apercu-medium-italic: "apercu-medium-italic";
$apercu-regular-pro: "apercu-regular-pro";
$apercu-mono-medium-pro: "apercu-mono-medium-pro";
$apercu-bold: "apercu-bold";
$apercu-bold-italic: "apercu-bold-italic";

$Garamond-BdNarrow: "Garamond-BdNarrow";
$Garamond-BdNarrowlta: "Garamond-BdNarrowlta";
$Garamond-BkNarrow: "Garamond-BkNarrow";
$Garamond-BkNarrowIta: "Garamond-BkNarrowIta";
$Garamond-LtNarrow: "Garamond-LtNarrow";
$Garamond-LtNarrowIta: "Garamond-LtNarrowIta";

// Caption
$apercu-medium-pro: "apercu-medium-pro";

// Font Families
$base-font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$sans-serif: "Source Sans Pro", Helvetica, Verdana, sans-serif;
$serif: Merriweather, Georgia, "Times New Roman", serif;
$mono: "Source Code Pro", Courier, mono;

$body-font-family: $apercu-medium-pro, $base-font-family;
$heading-font-family: $Garamond-BkNarrow, $base-font-family;

body {
  font-family: $body-font-family;
  background-color: $white;
  color: $dark;

  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $orange;
  font-family: $apercu-bold;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: $orange;
    text-decoration: underline;
  }
}

p {
  font-family: $apercu-regular-pro;
  font-size: 1.125rem;
  line-height: 1.625rem;
  letter-spacing: 0;
  padding: 0;
  color: $dark;

  &.fw-regular {
    font-family: $apercu-regular-pro;
  }
  &.fw-medium {
    font-family: $apercu-medium-pro;
  }
  &.fw-bold {
    font-family: $apercu-bold;
  }
  &.fst-italic {
    font-family: $apercu-light-italic;
  }
  /* Big Body Text */
  &.fs-1 {
    font-family: $apercu-regular-pro;
    line-height: 2.25rem;
    letter-spacing: -0.8px;
  }
  /* Body Text Medium */
  &.fs-2 {
    font-family: $apercu-medium-pro;
    line-height: 1.625rem;
    letter-spacing: 0;
    margin-bottom: 3.75rem;

    &.fw-bold {
      font-family: $apercu-bold;
    }
    &.fw-normal {
      font-family: $apercu-regular-pro;
    }
    &.fst-italic {
      font-family: $apercu-regular-pro;
      font-style: italic;
    }
  }
  /* Body Text Small */
  &.fs-3 {
    font-family: $Garamond-BkNarrowIta;
    line-height: 1.5rem;
  }
  &.fs-4 {
    font-family: $apercu-regular-pro;
    line-height: 1.25rem;
    &.fw-bold {
      font-family: $apercu-bold;
      line-height: 1.375rem;
    }
  }
  @include media-breakpoint-down(md) {
    font-size: 1rem;
    line-height: 1.6rem;

    &.non-resp {
      font-size: 1.125rem;
      line-height: 1.625rem;
    }
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $heading-font-family;
  &.fw-bold,
  & strong {
    font-family: $Garamond-BdNarrow;
    font-weight: normal;
  }
  &.sans {
    font-family: $apercu-medium-pro;
  }
  &.font-monospace {
    font-family: $apercu-mono-medium-pro;
  }
}

h1,
.h1 {
  font-size: 3.75rem;
  line-height: 2.875rem;
  letter-spacing: -1.71px;
  font-family: $Garamond-BkNarrow;
  top: 12px;

  @include media-breakpoint-up(md) {
    font-size: 5.625rem;
    line-height: 4.188rem;
    letter-spacing: -2.57px;
    margin-bottom: 3.75rem;
  }
}
h2,
.h2 {
  font-size: 3.25rem;
  line-height: 2.625rem;
  letter-spacing: -1.49px;
  font-family: $Garamond-BkNarrow;

  @include media-breakpoint-up(md) {
    font-size: 5rem;
    line-height: 4.063rem;
    letter-spacing: -2.29px;
    margin-bottom: 1.875rem;
  }
}
h3,
.h3 {
  font-size: 2.5rem;
  line-height: 1.875rem;
  letter-spacing: -1.14px;

  font-family: $Garamond-BkNarrow;

  @include media-breakpoint-up(md) {
    font-size: 3rem;
    line-height: 2.25rem;
    letter-spacing: -1.37px;
    margin-bottom: 1.875rem;
  }
}
h4,
.h4 {
  font-size: 1.75rem;
  line-height: 1.5rem;
  letter-spacing: -0.57px;
  font-family: $Garamond-BkNarrow;

  @include media-breakpoint-up(md) {
    font-size: 2.188rem;
    line-height: 2rem;
    letter-spacing: -0.44px;
    margin-bottom: 1.25rem;
  }
  &.fw-bold,
  & strong {
    font-size: 2.5rem;
  }
}
h5,
.h5 {
  font-size: 1.875rem;
  line-height: 1.625rem;
  letter-spacing: -0.86px;
  margin-bottom: 2.063rem;
  font-family: $Garamond-BkNarrow;

  @include media-breakpoint-up(md) {
    font-size: 2rem;
    line-height: 1.8rem;
    letter-spacing: -0.91px;
  }
}
h6,
.h6 {
  font-size: 1.313rem;
  line-height: 1.125rem;
  letter-spacing: -0.26px;
  margin-bottom: 0.625rem;
  font-family: $Garamond-BkNarrow;

  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
    line-height: 1.375rem;
    letter-spacing: -0.3px;
    margin-bottom: 1.25rem;
  }
  &.fw-bold,
  & strong {
    font-family: $apercu-bold;
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
}

//Typography styling for forms can be found in > _forms.scss
//Typography styling for navigation can be found in > _navigation.scss
//Typography styling for footer can be found in > _footer.scss
//Typography styling for links can be found in > _links.scss
//Typography styling for accordion can be found in > _accordion.scss

// Text Decoration classes
.underline {
  text-decoration-line: underline;
}
.overline {
  text-decoration-line: overline;
}
.line-through {
  text-decoration-line: line-through;
}
.no-underline {
  text-decoration-line: none;
}

blockquote {
  font-size: 2.25rem;
  line-height: 1.75rem;
  letter-spacing: -1.03px;
  margin-bottom: 0.625rem;
  font-family: $Garamond-BkNarrow;

  @include media-breakpoint-up(md) {
    font-size: 3rem;
    line-height: 2.25rem;
    letter-spacing: -1.37px;
    margin-bottom: 20px;
  }
  &.fw-bold,
  & strong {
    font-family: $Garamond-BdNarrow;
    font-weight: normal;
  }
}

figcaption {
  &.fs-4 {
    font-family: $apercu-regular-pro;
    line-height: 1.25rem;
  }
}
