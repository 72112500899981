/* Cards */
.block-vacature-kaarten {

    .card {

        &[data-url] {
            cursor: pointer;
        }
        .card-title {
            min-height: 44px;
        }
        .img-container {
            background-color: $orange;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            display: block;
            width: 100%;
            height: auto;
        }
    }

}
