

// Variables for sdb theme
:root[data-site="sdb"] {
	--dynamic-color-orange: #ff6600;
	--dynamic-color-orange-light: #FFE0CB;
	--dynamic-color-orange-20: rgba(255, 102, 0, 0.2);
	--dynamic-filter-orange: invert(41%) sepia(65%) saturate(2183%) hue-rotate(1deg) brightness(102%) contrast(106%);
	--dynamic-sdb-white-hieroo-dark: #fff;
	--dynamic-sdb-orange-hieroo-dark: #FF6600;
}
  
  // Variables for hieroo theme
  :root[data-site="hieroo"] {
	--dynamic-color-orange: #FAC50C;
	--dynamic-color-orange-light: #FDEEB6;
	--dynamic-color-orange-20: rgba(250, 197, 012, 0.3);
	--dynamic-filter-orange: invert(87%) sepia(77%) saturate(626%) hue-rotate(349deg) brightness(101%) contrast(102%);
	--dynamic-sdb-white-hieroo-dark: #333333;
	--dynamic-sdb-orange-hieroo-dark:  #333333;
  }

body,
html {
	max-width: 100%;
	min-height: 100% !important;
}

::selection {
	color: $white;
	background-color: $orange;
}

body {
	position: relative;
}

html {
	scroll-behavior: smooth;
}

figure {
	margin-bottom: 0;
}

:is(a, button, input, textarea) :focus {
	outline: none !important;
	box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25) !important; // override bootstrap colors/box-shadow
}

.hidden {
	visibility: hidden;
	display: none;
}

.show {
	visibility: unset;
}

.display-none {
	display: none !important;
}

.img-overlay-orange {
	background-color: $orange-20;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
}

.block-afbeelding {
	.img-overlay-orange {
		left: 12px;
		right: 12px;
		width: auto;
	}
}

@include media-breakpoint-down(md) {
	.container {
		padding-left: 30px;
		padding-right: 30px;
	}
}

.bg-animation-sdb {
	position: fixed;
	height: 100vh;
	width: 100vw;
	background: linear-gradient(-20deg, #fff 0%, #fff 50%, #F6F6F6 50%, #fff 80%);
	z-index: -1;
}

.bg-animation-hieroo {
	z-index: -1;
	width: 50vw;
	height: 100vh;
	display: flex;
	flex-direction: row;	
  	justify-content: end;
  	align-items: center;
  	top: 0;
	position: fixed;
  	overflow: visible;
	  .shape {
		position: absolute;
		height: 550vh;
		width:  550vh;
		border-radius: 50%;
		background: radial-gradient(circle, rgba(247,247,247,0) 0%, rgba(247,247,247,1) 100%);
		animation: rotate 5s linear infinite;
		animation-play-state: paused;
		animation-delay: calc(var(--scroll) * -3s);
		transition: transform 1s linear;
		transform-origin: center right;
	  }
	  @keyframes rotate {
			to {
		  transform: rotate(360deg);
		}
	  }
	
}

