.tabs {


  h2 {
    margin-bottom: 30px;
    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }
  }
  .tab-header {

    @include media-breakpoint-up(md) {
      background-color: $white;
      display: inline-block;  
      border-radius: 50px;
      margin: 0 auto;
    }

    .btn__lrg {
      padding: .1rem 1.275rem;
      margin-bottom: 1.25rem;
      &:before {
        transition: none;
      }
      &:hover {
        color: $dark;
        &:before {
          background-color: $gray;
        }
        &:after {
          background-color: transparent;
        }
      }
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        padding: 0.1rem 1.275rem;  
      }
      &.active {
        color: white;

        @include media-breakpoint-up(md) {
          color: $white;
        }

        &:after {
          background-color: $dark;
        }

        &:hover {
          &:before {
            background-color: $dark;
          }
        }
      }
    }
  }

  .tab-content {
    > .tab-pane {
      display: none;

      &.active {
        display: flex;
      }

      picture {

        @include media-breakpoint-up(md) {
          position: relative;
        }

        img {
          border-top-left-radius: 225px;
          border-top-right-radius: 225px;
          height: 300px;
          width: 100%;
          object-fit: cover;
          @include media-breakpoint-up(md) {
            border-top-right-radius: 0;
            border-bottom-left-radius: 225px;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: cover;
          }
        }

        @include media-breakpoint-up(md) {
          width: 50%;
        }
      }

      @include media-breakpoint-up(md) {
        height: 450px;
      }

      .content {
        border-bottom-left-radius: 225px;
        border-bottom-right-radius: 225px;
        
        @include media-breakpoint-up(md) {
          width: 50%;
          border-bottom-right-radius: 225px;
          border-bottom-left-radius: 0;
          border-top-right-radius: 225px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 60px!important;

          h3, p {
            max-width: 310px;
          }

          .btn__lrg {
            margin-right: auto;
          }
        }

        ul, ol {
          padding-left: 1rem;
          margin-bottom: 30px;

          li {
            position: relative; 
            padding-left: 1.5rem; 
            font-family: $apercu-regular-pro;
            font-size: 1.125rem;
            line-height: 1.625rem;

            &::before {
              content: "\2022"; 
              position: absolute;
              left: 0; 
              top: 0.3rem; 
              font-size: 20px;
              line-height: 20px;
              color: inherit; 
            }
          }
        }

        .btn__lrg {
          padding: .1rem 1rem;
        }
      }
    }
  }
}