/* Sticky CTA */
.sticky__wrapper {
  z-index: 76;
  bottom: 20px;
}

/* Fade in on page load */
.appear {
  opacity: 0;

  &.appear-in {
    opacity: 1;
    animation-name: appear-in;
    animation-duration: 1s;

    @keyframes appear-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

/* Global CTAs */
.cta {
  min-height: 120px;
  position: relative;
  z-index: 1;
  padding: 0 1.25rem;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  @include media-breakpoint-up(md) {
    padding: 0 3.75rem;
  }

  &.cta__primary {
    min-height: 80px;
    color: $black;
    flex-direction: row;
    background: rgba($light, 0.6);
    border-radius: $border-radius-cta;
    backdrop-filter: blur(30px);

    h5 {
      @include media-breakpoint-down(md) {
        margin-top: 0;
        font-size: 1.25rem;
        line-height: 1.125rem;
      }

      & > * {
        flex: 1 auto;
      }
    }
  }

  &.cta__secondary {
    max-width: 768px;
    column-count: 3;
    column-gap: 30px;

    h5 {
      max-width: 370px;
      @include media-breakpoint-up(md) {
        max-width: 246px;
      }
    }

    p {
      max-width: 280px;
    }

    img {
      width: 180px;
      min-width: 180px;
      height: 180px;
      border-radius: 50%;

      @include media-breakpoint-down(md) {
        margin: 0 auto 30px;
        width: 200px;
        height: 200px;
      }
    }

    &::before {
      position: absolute;
      background: $gray;
      border-radius: $border-radius-cta;
      content: '';
      width: 100%;
      min-height: 120px;
      left: 0;
      right: 0;

      margin: 0 auto;
      z-index: -1;

      @include media-breakpoint-down(md) {
        background: $gray;
        width: 174px;
        height: 100%;
        top: 0;
      }
    }
    @include media-breakpoint-down(md) {
      height: 100%;
      padding: 100px 10px;
    }
  }

  &.cta__sticky {
    color: $black;
    background: rgba($light, 0.6);
    border-radius: $border-radius-cta;

    @include media-breakpoint-down(md) {
      justify-content: start;
      background: none;

      & .btn__lrg {
        margin: 1rem 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.no-img {
  .cta {
    &.cta__secondary {
      &::before {
        bottom: 0;
        top: 0;
      }
    }
  }
}

.block-cta-small:not(.sticky__wrapper) {
  &.no-img {
    .block-cta-small__copy {
      max-width: 406px;
      @include media-breakpoint-up(md) {
        padding: 2.5rem 0;
      }
    }
    .cta {
      &.cta__secondary {
        column-gap: 2.5rem;
        h5 {
          max-width: inherit;
          padding-left: 0;
        }
        p {
          max-width: inherit;
        }
      }
    }
  }
}

/* Customization for Personen Details CTA */
.personen {
  .cta {
    &.cta__secondary {
      @include media-breakpoint-up(md) {
        padding: 0 2.5rem;
      }
      a {
        font-size: 1rem;
        line-height: 1.1875rem;
      }
    }
  }
}
