form.search-form {
    input[name="s"] {
        height: 52px;
        &:focus {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            border-color: $orange;
        }
    }
    button{
        transition: opacity 0.5s ease-in-out;
        background: none;
        appearance: none;
        z-index: 73;
        &.search-reset {
            opacity: 0;
        }
    }
    &.searching {
        button[type="submit"] {
            opacity: 0;
            pointer-events: none;
        }
        button.search-reset {
            opacity: 1;
        }
    }

    &.search-form-dark {
        input[name="s"] {
            background-color: $lightTransparent;
            font-family: $Garamond-LtNarrowIta;
            font-size: 18px;
        }
    }
}