// Bootstrap Variables to Override

// Color system
$orange: #ff6600;
$dark: #333333;
$light: #e5e5e5;
$lightTransparent: #e5e5e5cc;
$darkTransparent: #333333aa;
$placeholderGray: #d3d3d3;
$gray: #f7f7f7;
$white: #fff;
$black: #000;

// colors-map
$colors: (
  "black": $black,
  "gray": $gray,
  "orange": $orange,
  "white": $white,
);

// gray-colors-map
$grays: ();

// theme-colors-map
$theme-colors: (
  "light": $light,
  "dark": $dark,
  "gray": $gray
);

// scss-docs-start spacer-variables-maps (extends into padding/margin utility classes
// remember:
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  10: $spacer * calc(10 / 16),
  20: $spacer * calc(20 / 16),
  25: $spacer * calc(25 / 16),
  30: $spacer * calc(30 / 16),
  35: $spacer * calc(35 / 16),
  40: $spacer * calc(40 / 16),
  50: $spacer * calc(50 / 16),
  60: $spacer * calc(60 / 16),
  80: $spacer * calc(80 / 16),
  90: $spacer * calc(90 / 16),
  100: $spacer * calc(100 / 16),
  120: $spacer * calc(120 / 16),
  160: $spacer * calc(160 / 16),
);

// Body
$body-color: $dark;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

// Gutters
$gutters: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  12: $spacer * 0.75,
);

// Components
$border-radius: 0.25rem;
$border-radius-pill: 1.5rem !default;

// Harmonica/ Accordian

$accordion-padding-x: 0 1.25rem;
$accordion-button-focus-box-shadow: none;
$accordion-bg: $light;
$accordion-button-active-bg: none;
$accordion-button-active-color: $black;
$accordion-button-padding-y: 30px 30px;
$accordion-body-padding-x: 0px 30px;
$accordion-border-color: rgba($black, 0);
$accordion-button-icon: url("/../assets/accordion__plus.svg");
$accordion-button-active-icon: url("/../assets/accordion__minus.svg");

// Forms
$input-padding-x: 30px;
$input-padding-y: 0px;
$input-border-radius: 100px !important;
$input-border-color: transparent;
$input-border-width: 2px;
$input-focus-border-color: $dark;

$input-placeholder-color: $light;
$input-plaintext-color: $dark;

$input-height: 52px;
$input-height-lg: 42px;

$form-check-input-width: 1.25em;
$form-check-padding-start: $form-check-input-width + 0.5em;
$form-check-margin-bottom: 0px;

$form-check-input-bg: $white;
$form-check-input-border: 0px solid $white;
$form-check-input-border-radius: 0px;

$form-check-input-checked-color: $white;
$form-check-input-checked-bg-color: var(--dynamic-color-orange);

$form-check-radio-border-radius: 50%;

:root[data-site="sdb"] {
  --dynamic-checkbox-url: url("/../assets/img/icons/check.svg");
}
:root[data-site="hieroo"] {
  --dynamic-checkbox-url: url("/../assets/img/icons/check-dark.svg");
  }  

$form-check-input-checked-bg-image: var(--dynamic-checkbox-url);
$form-check-radio-checked-bg-image: none;

$form-feedback-invalid-color:  var(--dynamic-sdb-orange-hieroo-dark);

// Fonts
$font-size-root: null;
$font-size-base: null;

$font-sizes: (
  1: 1.75rem,
  2: 1.125rem,
  3: 1.125rem,
  4: 1rem,
  5: 0.8rem,
);

$hr-margin-y: 0;
$hr-color: $dark;
$hr-opacity: 1;

// Navigation
$transition-collapse: height 0.35s ease;

// Tooltips
$tooltip-bg: $placeholderGray;
$tooltip-border-radius: 5px;
$tooltip-color: $dark;
$tooltip-padding-y: 10px;
$tooltip-padding-x: 10px;
$tooltip-opacity: 1;
$tooltip-font-size: 0.6875rem;
$tooltip-arrow-width: 1rem;
$tooltip-arrow-height: 1rem;
