.backdrop {
  background-color: rgba(51, 51, 51, 0.9);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99999;

  .logo-container {
    position: relative;
    width: 150px;
    height: 150px;
  }

  h2 {
    color: #ffff;
    margin-top: 40px;
    text-align: center;
    font-weight: 700;
    line-height: 75px;
    letter-spacing: -2.286px;
  }

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation-timing-function: ease-in-out;
  }

  .logo-1,
  .logo-2 {
    animation-duration: 4s;
    animation-fill-mode: forwards;
  }
}

@keyframes spinLogo1Forward {
  0% {
    opacity: 1;
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-65deg);
  }
  48% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    transform: rotate(1440deg);
  }
}

@keyframes spinLogo2Forward {
  0% {
    opacity: 0;
    transform: rotate(-65deg);
    animation-timing-function: cubic-bezier(0.5, 0, 0.9, 0.7);
  }
  30% {
    transform: rotate(0deg);
    animation-timing-function: ease-in-out;
  }
  49% {
    opacity: 0;
  }
  52% {
    opacity: 1;
  }
  75% {
    opacity: 1;
    transform: rotate(785deg);
  }
  95% {
    opacity: 1;
    transform: rotate(715deg);
  }
  100% {
    opacity: 1;
    transform: rotate(720deg);
  }
}

@keyframes spinLogo1Reverse {
  0% {
    opacity: 1;
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(65deg);
  }
  48% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    transform: rotate(-1440deg);
  }
}

@keyframes spinLogo2Reverse {
  0% {
    opacity: 0;
    transform: rotate(65deg);
    animation-timing-function: cubic-bezier(0.5, 0, 0.9, 0.7);
  }
  30% {
    transform: rotate(0deg);
    animation-timing-function: ease-in-out;
  }
  49% {
    opacity: 0;
  }
  52% {
    opacity: 1;
  }
  75% {
    opacity: 1;
    transform: rotate(-785deg);
  }
  95% {
    opacity: 1;
    transform: rotate(-715deg);
  }
  100% {
    opacity: 1;
    transform: rotate(-720deg);
  }
}
